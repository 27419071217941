@import 'variables';

@mixin breakpoint($min-width, $max-width: null) {
  @if $min-width and $max-width {
    @media (min-width: $min-width) and (max-width: $max-width) {
      @content;
    }
  } @else {
    @media (min-width: $min-width) {
      @content;
    }
  }
}

@mixin button-shadow($color) {
  box-shadow: 0 4px 6px -1px rgba($color, 0.2), 0 2px 4px -1px rgba($color, 0.2);
}

@mixin container() {
  background-color: $dark-gray;
  border-radius: 0.5rem;
  padding: 1.5rem;

  @include breakpoint($s) {
    padding: 3rem;
  }

  @include breakpoint($m) {
    padding: 4rem;
  }
}

@mixin diagonal-gradient($a, $b) {
  background-color: $a;
  background-image: linear-gradient(45deg, $a, $b);
}

@mixin drop-shadow($color) {
  filter: drop-shadow(0.125rem 0.25rem 0.375rem $color);
}

@mixin scrollbar() {
  scrollbar-color: white;
  scrollbar-width: 0.4rem;

  &::-webkit-scrollbar {
    height: 0.4rem;
    width: 0.4rem;
  }

  &::-webkit-scrollbar-thumb {
    background-image: linear-gradient($red, $grape);
  }
}
