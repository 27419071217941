@import '../styles/mixins';

.contact {
  @include container();
  border-radius: 0.5rem 0.5rem 0 0;
  text-align: center;

  &__heading {
    margin-bottom: 1.25rem;
  }

  &__line {
    align-items: center;
    display: flex;
    justify-content: center;

    &:first-child {
      margin: 0 0 0.5rem 0;
    }
  }

  &__icon {
    margin-right: 1rem;

    &--email {
      font-size: 1.6rem;
    }

    &--phone {
      font-size: 1.3rem;
    }
  }

  &__link {
    color: white;
  }

  &__break {
    display: none;
  }

  &__by {
    margin: 1.75rem 0 0 0;
  }

  @include breakpoint($m) {
    &__heading {
      margin-bottom: 1rem;
    }

    &__contacts {
      display: flex;
      justify-content: center;
    }

    &__line {
      display: inline-flex;

      &:first-child {
        margin: 0 2rem 0 0;
      }
    }

    &__icon {
      &--email {
        font-size: 2rem;
      }

      &--phone {
        font-size: 1.6rem;
      }
    }

    &__break {
      background-color: $darkish-gray;
      border: none;
      display: block;
      height: 3px;
      margin: 2rem auto;
      width: 28rem;
    }

    &__by {
      margin: 0;
    }
  }
}
