@import '../styles/mixins';

.experience {
  margin-bottom: 4rem;

  &__category {
    margin: 0 auto 4rem auto;

    &--education {
      > .experience__group {
        &:first-of-type .experience__date {
          @include diagonal-gradient($red, $grape);
        }

        &:nth-of-type(2) .experience__date {
          @include diagonal-gradient($cyan, $indigo);
        }
      }

      > .experience__line {
        background-image: linear-gradient($indigo, $cyan);
      }
    }

    &--work {
      > .experience__group {
        &:first-of-type .experience__date {
          @include diagonal-gradient($orange, $yellow);
        }

        &:nth-of-type(2) .experience__date {
          @include diagonal-gradient($green, $teal);
        }

        &:nth-of-type(3) .experience__date {
          @include diagonal-gradient($orange, $pink);
        }

        &:nth-of-type(4) .experience__date {
          @include diagonal-gradient($teal, $blue);
        }

        &:nth-of-type(5) .experience__date {
          @include diagonal-gradient($grape, $red);
        }
      }

      > .experience__line {
        &:first-of-type {
          background-image: linear-gradient($teal, $green);
        }

        &:nth-of-type(2) {
          background-image: linear-gradient($pink, $orange);
        }

        &:nth-of-type(3) {
          background-image: linear-gradient($blue, $teal);
        }

        &:nth-of-type(4) {
          background-image: linear-gradient($red, $grape);
        }
      }
    }

    &--volunteer {
      > .experience__group {
        &:first-of-type .experience__date {
          @include diagonal-gradient($grape, $blue);
        }

        &:nth-of-type(2) .experience__date {
          @include diagonal-gradient($red, $yellow);
        }
      }

      > .experience__line {
        background-image: linear-gradient($yellow, $red);
      }
    }
  }

  &__heading {
    text-align: center;
  }

  &__group {
    background-color: $dark-gray;
    border-radius: 1rem;
    color: white;

    &:nth-of-type(2n) .experience__headings {
      text-align: right;
    }
  }

  &__topline {
    display: flex;
    flex-direction: column;
  }

  &__headings {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 1.5rem 1.5rem 0 1.5rem;
  }

  &__h4 {
    font-size: 1.125rem;
    margin: 0;
    text-transform: uppercase;
  }

  &__h5 {
    font-size: 0.9rem;
    margin: 0.5rem 0 0 0;
    text-transform: uppercase;
  }

  &__date {
    border-radius: 1rem 1rem 0 0;
    font-family: $fira-mono;
    font-size: 0.9rem;
    font-weight: 500;
    order: -1;
    padding: 1rem 0;
    text-align: center;
    @include button-shadow(black);
  }

  &__break {
    display: none;
  }

  &__body {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }

  &__rule {
    background-color: $darkish-gray;
    border: none;
    height: 3px;
    margin: 1.5rem 0;
  }

  &__text:last-child {
    margin-bottom: 0;
  }

  &__line {
    display: block;
    height: 4rem;
    margin-left: calc(50% - 0.25rem);
    width: 0.25rem;
  }

  @include breakpoint($s) {
    &__topline {
      flex-direction: row;
      justify-content: space-between;
    }

    &__headings {
      margin: 0 2rem 0 0;
      padding: 2rem 0 0 2rem;

      &:last-child {
        margin: 0 0 0 2rem;
        padding: 2rem 2rem 0 0;
      }
    }

    &__date {
      align-items: center;
      border-radius: 1rem 0;
      display: flex;
      height: 6rem;
      justify-content: center;
      min-width: 6rem;
      order: initial;
      padding: 0;

      &:last-child {
        border-radius: 0 1rem;
      }
    }

    &__break {
      display: inline;
    }

    &__body {
      padding: 0 2rem 2rem 2rem;
    }

    &__rule {
      margin: 2rem 0 1.625rem 0;
    }
  }

  @include breakpoint($l) {
    &__categories {
      display: grid;
      gap: 4rem;
      grid-template-columns: repeat(4, 1fr);
      justify-items: center;
    }

    &__category {
      grid-column-end: span 2;
      margin: 0;

      &:last-child:nth-child(2n - 1) {
        grid-column-start: 2;
      }
    }

    &__h4 {
      font-size: 1rem;
    }
  }

  @include breakpoint($xl) {
    &__h4 {
      font-size: 1.125rem;
    }
  }

  @include breakpoint($xxxl) {
    &__categories {
      grid-template-columns: repeat(3, 1fr);
      justify-items: center;
    }

    &__category {
      grid-column-end: initial;

      &:last-child:nth-child(2n - 1) {
        grid-column-start: initial;
      }
    }
  }
}
