@import '../styles/mixins';

.skills {
  margin-bottom: 4rem;
  @include container();

  &__filters-wrapper {
    margin: 0 -0.75rem -0.75rem 0;
  }

  &__tag {
    background-color: white;
    border: none;
    border-radius: 0.25rem;
    color: $dark-gray;
    cursor: pointer;
    font-family: $fira-mono;
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1;
    margin: 0 0.75rem 0.75rem 0;
    padding: 0.5rem 1rem;
    transition: background-color $time, box-shadow 0.4s, transform $time;
    @include button-shadow(black);

    &:hover {
      transform: translateY(-0.05rem);
    }

    &:active {
      transform: translateY(0.1rem);
    }

    @for $i from 1 through length($colors) {
      $color: nth($colors, $i);

      &:nth-child(12n + #{$i}) {
        &.skills__tag--active {
          background-color: $color;
        }

        @media (hover: hover) {
          &:hover {
            background-color: $color;
            @include button-shadow($color);
          }
        }
      }
    }
  }

  &__separator {
    background-color: $darkish-gray;
    height: 0.2rem;
    margin: 2rem 0;
  }

  &__skills {
    margin: 0 -0.5rem -0.5rem 0;
  }

  &__skill {
    border: 0.15rem solid;
    border-radius: 0.25rem;
    display: inline-block;
    font-family: $fira-mono;
    font-size: 0.9rem;
    margin: 0 0.5rem 0.5rem 0;
    max-width: calc(100% - 3rem);
    overflow: hidden;
    padding: 0.25rem 1rem;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;

    @for $i from 1 through length($colors) {
      &:nth-child(12n + #{$i}) {
        $color: nth($colors, $i);
        border-color: $color;
      }
    }
  }

  @include breakpoint($s) {
    &__tag,
    &__skill {
      font-size: 1rem;
    }
  }

  @include breakpoint($xl) {
    &__main {
      display: flex;
    }

    &__filters {
      flex: 3;
      margin-right: 2rem;
    }

    &__separator {
      display: none;
    }

    &__skills {
      flex: 5;
    }
  }

  @include breakpoint($xxl) {
    &__filters {
      flex: 3;
    }

    &__skills {
      flex: 7;
    }
  }
}
