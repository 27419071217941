@import-normalize;
@import 'styles/mixins';

body {
  background-color: black;
  color: white;
  font-family: $nunito;
  font-size: 0.9rem;
  margin: 0;
  @include scrollbar();

  &.overlayed {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $halant;
}

h1 {
  font-size: 2.2rem;
}

h2 {
  font-size: 2rem;
}

h2,
h3 {
  margin: 0 0 1rem 0;
}

h3 {
  font-size: 1.625rem;
}

p {
  line-height: 1.4rem;
}

a {
  color: $blue;
  font-weight: bold;
  text-decoration: none;
  transition: color $time;

  &:hover,
  &:active {
    color: $pink;
  }
}

img,
a,
button,
.hamburger-react {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@include breakpoint($s) {
  body {
    font-size: 1rem;
  }

  h1 {
    font-size: 3.2rem;
  }

  h2 {
    font-size: 2.75rem;
  }

  h3 {
    font-size: 2.25rem;
  }
}

@include breakpoint($m) {
  body {
    font-size: 1.1rem;
  }

  h1 {
    font-size: 4.1rem;
  }

  h2 {
    font-size: 3.5rem;
  }
}

@include breakpoint($l) {
  h1 {
    font-size: 5.5rem;
  }
}

@include breakpoint($xl) {
  h1 {
    font-size: 3.9rem;
  }
}

@include breakpoint($xxl) {
  h1 {
    font-size: 4.4rem;
  }
}
