@import '../styles/mixins';

.banner {
  margin-bottom: 4rem;
  padding-top: 2rem;

  &__text {
    text-align: center;
    margin-bottom: 2rem;
  }

  &__heading {
    margin: 0;
  }

  &__tech {
    display: none;
  }

  @include breakpoint($xl) {
    display: flex;

    &__text {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      text-align: left;
    }

    &__tech {
      display: grid;
      grid-template: repeat(4, 1fr) / repeat(9, 1fr);
      gap: 1rem;
      width: 26rem;
    }

    &__square {
      align-items: center;
      border-radius: 1rem;
      display: flex;
      font-size: 2rem;
      grid-column-start: span 2;
      height: 5rem;
      justify-content: center;
      width: 5rem;
      transition: transform $time;

      &:hover {
        transform: scale(1.05, 1.05);
      }

      $gradients: ($cyan, $lime), ($orange, $pink), ($yellow, $orange),
        ($red, $grape), ($grape, $red), ($violet, $cyan), ($green, $yellow),
        ($pink, $orange), ($teal, $blue), ($yellow, $green), ($red, $yellow),
        ($cyan, $indigo), ($grape, $yellow), ($blue, $teal), ($pink, $indigo),
        ($lime, $teal);

      @for $i from 1 through length($gradients) {
        &:nth-child(#{$i}) {
          $gradient: nth($gradients, $i);
          @include diagonal-gradient(nth($gradient, 1), nth($gradient, 2));
        }
      }

      &:nth-child(8n + 5) {
        grid-column: 2 / 4;
      }
    }

    &__icon {
      @include drop-shadow(rgba(black, 0.35));
    }
  }

  @include breakpoint($xxl) {
    &__tech {
      gap: 2rem;
      width: 34rem;
    }

    &__square {
      height: 6rem;
      width: 6rem;
    }
  }
}
