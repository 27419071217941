@import 'styles/mixins';

.main {
  margin: 5rem auto 0 auto;
  max-width: 100rem;
  padding: 0 2rem;

  @include breakpoint($s) {
    margin-top: 6rem;
    padding: 0 4rem;
  }

  @include breakpoint($m) {
    padding: 0 6rem;
  }
}
