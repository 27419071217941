@import '../styles/mixins';

.bio {
  margin-bottom: 4rem;

  &__image-container {
    display: none;
  }

  &__text {
    @include container();
  }

  &__contact {
    background-color: transparent;
    border: none;
    color: #4dabf7;
    cursor: pointer;
    font-family: $nunito;
    font-size: inherit;
    font-weight: bold;
    padding: 0;
    transition: color 0.2s;

    &:hover,
    &:active {
      color: $pink;
    }
  }

  &__resume-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__resume {
    background-color: $blue;
    border: none;
    border-radius: 0.25rem;
    color: white;
    display: inline-block;
    font-family: $fira-mono;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 1rem;
    padding: 0.5rem 1.25rem;
    text-align: right;
    transition: background-color $time, box-shadow 0.4s, transform $time;
    @include button-shadow(black);

    &:hover {
      color: white;
      background-color: $pink;
      transform: translateY(-0.1rem);
      @include button-shadow($pink);
    }

    &:active {
      transform: translateY(0.2rem);
    }
  }

  @include breakpoint($s) {
    &__resume {
      font-size: 1.25rem;
      padding: 0.75rem 2rem;
    }
  }

  @include breakpoint($xl) {
    display: flex;

    &__image-container {
      display: block;
      margin-right: 4rem;
    }

    &__image {
      border-radius: 0.5rem;
      display: inline-block;
      width: 20rem;
    }

    &__text {
      border-radius: 0.5rem;
    }
  }
}
